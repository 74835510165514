import { tryParseDate, tryParseTime } from "./dateTimeHelper";
export function dateValidationRule(options) {
    if (options.value == "")
        return true;
    const date = tryParseDate(this.locale, options.value);
    return date !== null && !isNaN(date.getDate());
}
;
export function timeValidationRule(options) {
    if (options.value == "")
        return true;
    const time = tryParseTime(this.locale, options.value);
    return time !== null && !isNaN(time.getTime());
}
;
